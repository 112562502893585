import { BLOCCPAY_URL } from "../constants";

const siteMetadata = {
  companyName: "Bloccpay App",
  description: `A crypto-powered payroll solution for global businesses &amp; talents. Pay your talents globally with ease &amp; get paid easily while doing the work you love.`,
  siteUrl: BLOCCPAY_URL,
  siteLogo: `https://bloccpay-assets.s3.amazonaws.com/assets/preview-image-twitter.png`,
  siteLogoSquare: `https://bloccpay-assets.s3.amazonaws.com/assets/preview-image-twitter.png`,
  email: "hello@bloccpay.com",
  twitter: "https://twitter.com/Bloccpay",
  twitterHandle: "Bloccpay",
  instagram: "https://www.instagram.com/Bloccpay"
};

export default siteMetadata;
