import { NotificationManager, NotificationProps } from "react-notifications";

export const notify = ({
  type,
  title,
  message,
  timeOut = 5000,
  onClick
}: NotificationProps) => {
  return NotificationManager[type](message, title, timeOut, onClick);
};
